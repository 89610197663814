var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateBack
          }
        }, [_vm._v("mdi-arrow-left")]), _c('span', [_vm._v(_vm._s(_vm.message && _vm.message.subject))])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm.message && _vm.message.sentFromKursAdmin ? _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": _vm.setUnread
          }
        }, [_c('v-icon', {
          attrs: {
            "left": !_vm.isMobile
          }
        }, [_vm._v("mdi-email-mark-as-unread")]), _vm._v(" " + _vm._s(!_vm.isMobile ? "Marker som ulest" : null) + " ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_c('BaseLoading', {
    staticClass: "pa-4",
    attrs: {
      "value": _vm.loading,
      "skeletonType": "sentence, paragraph@2"
    }
  }, [_vm.message ? _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "d-flex mb-4"
  }, [_c('div', {
    staticClass: "text--secondary mr-auto",
    attrs: {
      "data-cy": "message-sender"
    },
    domProps: {
      "textContent": _vm._s(_vm.message.sentFromKursAdmin ? ("Fra: " + (_vm.message.sender)) : ("Til: " + (_vm.message.recipients[0])))
    }
  }), _c('div', {
    staticClass: "text--secondary",
    domProps: {
      "textContent": _vm._s(_vm.formatDateTime(new Date(_vm.message.sent)))
    }
  })]), _c('div', [_c('v-card-title', {
    staticClass: "pa-0",
    attrs: {
      "data-cy": "message-subject",
      "data-private": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.message.subject) + " ")]), _c('viewer', {
    staticClass: "pb-4",
    attrs: {
      "data-cy": "message-text",
      "initialValue": _vm.message.text
    }
  }), _c('v-divider'), _vm.hasAttachment ? _c('div', {
    staticClass: "pt-4 d-flex"
  }, [_c('h4', {
    staticClass: "pt-1"
  }, [_vm._v("Vedlegg:")]), _vm._l(_vm.message.attachments, function (attachment) {
    return _c('v-chip', {
      key: attachment.fileId,
      staticClass: "mx-2",
      on: {
        "click": function ($event) {
          return _vm.displayFile(attachment);
        }
      }
    }, [_vm._v(" " + _vm._s(attachment.fileName) + " ")]);
  }), _c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": _vm.handleDownloadAttachments
    }
  }, [_c('v-icon', [_vm._v("mdi-download")])], 1)], 2) : _vm._e(), _vm.isMobile && _vm.hasAttachment ? _c('div', {
    staticClass: "text--secondary mr-auto"
  }, [_vm._v(" (Dersom du opplever problemer med visning av vedlegg på mobil, så anbefaler vi å gå inn via PC eller laste ned vedlegget og åpne den på mobilen) ")]) : _vm._e()], 1)])], 1) : _vm._e(), _c('BaseFileContainer', {
    attrs: {
      "isFilePreviewOpen": _vm.isFilePreviewOpen,
      "filePreview": _vm.filePreview
    },
    on: {
      "close": _vm.closeFilePreview
    }
  }), _c('v-form', {
    ref: "messageForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.message && _vm.message.sentFromKursAdmin ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "filled": "",
      "label": "Svar på melding",
      "auto-grow": "",
      "counter": "",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.replyText,
      callback: function ($$v) {
        _vm.replyText = $$v;
      },
      expression: "replyText"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "block": _vm.isMobile ? true : false
    },
    on: {
      "click": _vm.sendReply
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-email-edit-outline")]), _vm._v(" Send svar ")], 1)], 1)], 1) : _vm._e()], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }