
import { isMobile } from "@/shared/helpers/displayHelpers";
import { FilePreview } from "@/shared/helpers/fileHelpers";
import { defineComponent, PropType } from "@vue/composition-api";
import BaseFileView from "./BaseFileView.vue";
import BaseModal from "./modal/BaseModal.vue";

export default defineComponent({
  name: "BaseFileContainer",
  components: { BaseFileView, BaseModal },
  emits: ["close"],
  props: {
    filePreview: {
      type: Object as PropType<FilePreview>,
      required: true,
    },
    isFilePreviewOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      isMobile,
    };
  },
});
