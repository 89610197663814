// TODO use them!

import { validateMod11 } from "@/shared/utils/validateMod11";
import { validateNorwegianIdNumber } from "norwegian-national-id-validator";

export const validateIsBirthDate = (date: string) =>
  /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(
    date
  ) || "Ugyldig fødselsdato";

export const validateNotEmpty = (input: unknown) => !!input || "Feltet er påkrevd";

export const validateArrayNotEmpty = [
  (input: unknown[]) => !!input || "Feltet er påkrevd",
  (input: unknown[]) => input.length || "Feltet er påkrevd",
];

export const validateTimeInput = [
  (input: string) => {
    const [hours, minutes] = input.split(":");
    if (+hours >= 0 && +hours <= 23 && +minutes >= 0 && +minutes <= 59) {
      return +hours >= 0 && +hours <= 23 && +minutes >= 0 && +minutes <= 59;
    }
    return "Ikke gyldig tidsformat tt:mm";
  },
];

export const validateNotEmptyWithCustomMessage =
  (customMessage = "Feltet er påkrevd") =>
  (input: unknown) =>
    !!input || customMessage;

export const validateCourseHourInput = (minVal = 1, maxVal = 9999, validateHalfHour = false) => [
  (input: string) => !!input || "Feltet er påkrevd",
  (input: string) => (+input >= minVal && +input <= maxVal) || `Kan kun være nummer mellom ${minVal} og ${maxVal}`,
  (input: string) => (validateHalfHour ? /^\d*\.?(0|5)?$/.test(input) || "Kan ha kun halvtime intervall" : true),
];

export const validateAccountNumber = [
  (input: number) => (input.toString().length === 11 && +input >= 0) || "Kontonummer må bestå av 11 siffer",
  (input: number) => validateMod11(input) || "Kontonummeret er ugyldig",
];

export const validateOptionalAccountNumber = [
  (input: number) => !input || (input.toString().length === 11 && +input >= 0) || "Kontonummer må bestå av 11 siffer",
  (input: number) => !input || validateMod11(input) || "Kontonummeret er ugyldig",
];

export const validateIsEmail = (v: string) => /.+@.+\..+/.test(v) || "Ugyldig e-postadresse";

export const validateBoolean = (v: any) => /(?:true|false)/.test(v) || "Feltet er påkrevd";

export const validateChecked = (v: boolean) => v || "Feltet er påkrevd";

export const validateIsNorwegianPhoneNumber = (v: any) =>
  /^((0047)?|(\+47)?|(47)?)\d{8}$/.test(v) || "Ugyldig telefonnummer";

export const validateIsNorwegianZipCode = (v: any) => /^\d{4}$/.test(v) || "Ugyldig postnummer";

export const validateZipCode = (value: string) =>
  (/^\d+$/.test(value) && value.length <= 10) || `Postnummer kan ha maks antall 10 siffer og ingen bokstaver tillatt`;

export const validateNonNegativeNumber = (v: string) => +v >= 1 || "Tallet må være 1 eller høyere.";

export const validateMaxLength = (maxLength?: number) => (value: string) => {
  if (maxLength) {
    return value.length < maxLength || `Maks antall tegn er ${maxLength}`;
  }
  return true;
};

export const validNationalIdNumber = (nationalIdNumber?: number) => {
  if (nationalIdNumber) {
    return validateNorwegianIdNumber(nationalIdNumber.toString()) || "Fødselsnummer har ikke gyldig format";
  }
  return true;
};

export const validateMaxFileSize = (maxSizeMB: number) => (files: File[]) => {
  if (!files || !files.length) {
    return true;
  }
  return files.every((file) => file.size < 1024 ** 2 * maxSizeMB) || `Filen må være mindre enn ${maxSizeMB} MB`;
};

const currentYear = new Date().getFullYear();

export const validateParticipantMinAge = [
  (v: string) => !v || +v < +currentYear - 3 || "Deltakeren må være eldre enn 3 år",
];

export const validateParticipantMaxAge = [
  (v: string) => !v || +v >= currentYear - 100 || "Deltakeren kan ikke være eldre enn 100 år",
];

export const validateRange =
  (min = 0, max = 100000) =>
  (v: string) =>
    (+v >= min && +v <= max) || `Verdien må være mellom ${min} og ${max}`;

export const validateIsOptionalNorwegianPhoneNumber = (v: any) =>
  !v || /^((0047)?|(\+47)?|(47)?)\d{8}$/.test(v) || "Ugyldig telefonnummer";

export const validateIsOptionalNorwegianZipCode = (v: any) => !v || /^\d{4}$/.test(v) || "Ugyldig postnummer";

export const validateIsOptionalEmail = (v: string) => !v || /.+@.+\..+/.test(v) || "Ugyldig e-postadresse";
