import { ApiGetCourseRelatedFileDto } from "@/api/generated/Api";

export function isFileValidated(file: any, fileTypes: any) {
  if (!file || !fileTypes) {
    return false;
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let result: any;

    reader.onloadend = (e) => {
      if ((e as any).target.readyState !== FileReader.DONE) {
        return;
      }
      let header = "";
      const bytesArray = new Uint8Array((e as any).target.result).slice(0, 8);
      bytesArray.forEach((item) => {
        let hex = item.toString(16).toLowerCase();
        if (hex.length === 1) {
          hex = "0" + hex;
        }
        header += hex;
      });
      const fileSignatureIndex = fileTypes.findIndex(
        (type: any) => header.slice(0, type.signature.length) === type.signature
      );
      if (fileSignatureIndex > -1) {
        result = true;
      }
      resolve(result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export interface FileViewModel extends ApiGetCourseRelatedFileDto {
  insertedByFullName: string;
  participantUserFullName?: string;
  participantStatus?: string;
  participantRole?: string;
}

export interface FilePreview {
  name?: string;
  mimeType?: string | null;
  source?: string;
}

export const getInitialFilePreview: () => FilePreview = () => ({
  name: undefined,
  mimeType: undefined,
  source: undefined,
});
