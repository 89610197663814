
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseLoading",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    skeletonType: { type: String, default: null },
  },
});
