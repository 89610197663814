var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-overlay', {
    attrs: {
      "absolute": "",
      "color": "white",
      "value": _vm.value
    }
  }, [!_vm.skeletonType ? _c('v-progress-circular', {
    attrs: {
      "color": "primary",
      "indeterminate": ""
    }
  }) : _vm._e()], 1), _vm.skeletonType && _vm.value ? _c('v-skeleton-loader', {
    attrs: {
      "type": _vm.skeletonType
    }
  }) : _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }