
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseModal",
  props: {
    maxWidth: { type: String, default: "800px" },
  },
  setup() {
    return {};
  },
});
