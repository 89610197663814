
import { api, binaryApi } from "@/api/api";
import { ApiAttachmentDto, ApiGetMessageResultDto } from "@/api/generated/Api";
import BaseFileContainer from "@/components/shared/BaseFileContainer.vue";
import BaseFileView from "@/components/shared/BaseFileView.vue";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import BaseLoading from "@/components/shared/loading/BaseLoading.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import router from "@/router/router";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { getInitialFilePreview } from "@/shared/helpers/fileHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { withLoadingRef } from "@/shared/helpers/otherHelpers";
import { openNotification, runStoreAction } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/vue-editor";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { VForm } from "vuetify/lib";

const ReplyPrefix = "Re: ";

export default defineComponent({
  name: "Message",
  components: {
    BaseLayout,
    BaseLoading,
    Viewer,
    BaseFileContainer,
    BaseFileView,
    BaseModal,
  },
  props: {
    messageId: { type: Number, required: true },
  },

  setup(props) {
    const loading = ref(false);
    const { messageId } = props;
    const messageForm = ref<InstanceType<typeof VForm> & { validate: () => boolean }>();
    const message = ref<ApiGetMessageResultDto>();
    const replyText = ref("");
    const store = useStore<StoreState>();

    const isFilePreviewOpen = ref(false);
    const filePreview = ref(getInitialFilePreview());

    onMounted(
      withLoadingRef(loading, async () => {
        message.value = (await api.messaging.getMessageByIdMinSide(messageId)).data;
        if (message.value?.sentFromKursAdmin) {
          await api.messaging.setReadMinside(messageId, { isRead: true });
        }
        runStoreAction(store, StoreModules.Messages, StoreActions.MessagesActions.FetchUnreadMessages);
      })
    );

    const displayFile = async (file: ApiAttachmentDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!file.fileName) {
          return;
        }
        try {
          const attatchement = await binaryApi.file.downloadFileAsync(file.fileId, { format: "blob" });
          const blob = new Blob([attatchement.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          filePreview.value = {
            name: file.fileName,
            mimeType: "application/pdf",
            source: url,
          };
          isFilePreviewOpen.value = true;
        } catch {
          openNotification(store, NotificationItemType.Error, `En feil oppsto ved visning av ${file.fileName}.`);
        }
      });
    };

    function handleDownloadAttachments() {
      message.value?.attachments?.forEach((doc) => {
        const payload = {
          documentId: doc.fileId,
          fileName: doc.fileName,
        };
        runStoreAction(store, StoreModules.File, StoreActions.FileActions.DownloadFile, payload);
      });
    }

    const closeFilePreview = () => {
      isFilePreviewOpen.value = false;
      filePreview.value = getInitialFilePreview();
    };

    const setUnread = withLoadingRef(loading, async () => {
      await api.messaging.setReadMinside(messageId, { isRead: false });
      openNotification(store, NotificationItemType.Information, "Meldingen er markert som ulest", 5000);
      runStoreAction(store, StoreModules.Messages, StoreActions.MessagesActions.FetchUnreadMessages);
      router.push("/messages");
    });

    const sendReply = withLoadingRef(loading, async () => {
      if (!messageForm.value?.validate()) {
        return;
      }
      const subject = message.value?.subject || "(mangler emne)";
      const replySubject = subject.startsWith(ReplyPrefix) ? subject : ReplyPrefix + subject;
      await api.messaging.replyTo({
        ReplyTo: messageId,
        Subject: replySubject,
        Text: replyText.value,
      });
      openNotification(store, NotificationItemType.Success, "Meldingen er sendt", 5000);
      router.push("/messages");
    });

    return {
      loading,
      message,
      isMobile,
      replyText,
      formatDateTime,
      setUnread,
      isFilePreviewOpen,
      filePreview,
      handleDownloadAttachments,
      closeFilePreview,
      displayFile,
      sendReply,
      messageForm,
      validateNotEmpty,
      navigateBack: () => useNavigateBack("messages"),
      hasAttachment: computed(() => (message.value?.attachments ? message.value?.attachments?.length > 0 : false)),
    };
  },
});
