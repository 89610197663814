/** MOD11 check digit algorithm */
const mod11 = (input: number | string) => {
  // See https://no.wikipedia.org/wiki/MOD11 for specification
  const sum = input
    .toString()
    .split("")
    .reverse()
    .map(Number)
    .reduce((sum, current, index) => sum + current * ((index % 6) + 2), 0);
  const sumMod11 = sum % 11;
  if (sumMod11 === 1) {
    return "-";
  }
  if (sumMod11 === 0) {
    return 0;
  }
  return 11 - sumMod11;
};

export const validateMod11 = (input: number | string) => {
  const inputNumber = +input;
  const checkDigit = inputNumber % 10;
  const number = (inputNumber - checkDigit) / 10;
  return mod11(number) === checkDigit;
};
