var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isFilePreviewOpen && !_vm.isMobile ? _c('BaseModal', {
    attrs: {
      "maxWidth": "1500px"
    }
  }, [_c('BaseFileView', {
    attrs: {
      "filePreview": _vm.filePreview
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1) : _vm._e(), _vm.isMobile ? _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.isFilePreviewOpen,
      callback: function ($$v) {
        _vm.isFilePreviewOpen = $$v;
      },
      expression: "isFilePreviewOpen"
    }
  }, [_c('BaseFileView', {
    attrs: {
      "filePreview": _vm.filePreview
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }