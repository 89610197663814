var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseCard', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.filePreview.name) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "px-6",
          attrs: {
            "color": "primary",
            "block": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v(" Lukk ")])];
      },
      proxy: true
    }])
  }, [[_vm.isPdfType ? _c('iframe', {
    staticClass: "pdfIframe",
    attrs: {
      "src": _vm.filePreview.source,
      "type": "application/pdf",
      "width": "100%",
      "height": "100%"
    }
  }) : _c('v-img', {
    staticClass: "image-content",
    attrs: {
      "src": _vm.filePreview.source,
      "contain": ""
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }