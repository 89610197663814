
import { computed, defineComponent } from "@vue/composition-api";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";

export default defineComponent({
  name: "BaseCard",
  components: {
    BaseBreadCrumbs,
  },
  props: {
    mainPageCard: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    return {
      hasActionSlot: !!slots.actions,
      containerCssClass: computed(() => {
        return props.mainPageCard ? "" : "pa-0";
      }),
    };
  },
});
