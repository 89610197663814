
import { defineComponent, PropType } from "@vue/composition-api";
import BaseCard from "@/components/shared/BaseCard.vue";
import { FilePreview } from "@/shared/helpers/fileHelpers";

export default defineComponent({
  name: "CourseViewFileModal",
  components: { BaseCard },
  emits: ["close"],
  props: {
    filePreview: {
      type: Object as PropType<FilePreview>,
      required: true,
    },
  },
  setup(props) {
    const PDF_MIME_TYPE = "pdf";
    const isPdfType =
      props.filePreview.mimeType?.includes(PDF_MIME_TYPE) ||
      props.filePreview?.name?.toLocaleLowerCase().endsWith("pdf");

    return {
      isPdfType,
    };
  },
});
